import React from 'react'
import PropTypes from 'prop-types'

const Stamp = ({size, accent, style, animated }) => {

  const d = [
    0,
    100 * (1/3),
    100 * (2/3),
    100,
  ]

  const matrix = [
                  [d[1], d[0]], [d[2], d[0]],
    [d[0], d[1]], [d[1], d[1]], [d[2], d[1]], [d[3], d[1]],
    [d[0], d[2]], [d[1], d[2]], [d[2], d[2]], [d[3], d[2]],
                  [d[1], d[3]], [d[2], d[3]],
  ]

  const renderPoint = (c,i) => (
    <div
      key={i}
      className={`Stamp-dot ${accent ? 'accented' : ''}`}
      style={{
        left: `${c[0]}%`,
        top: `${c[1]}%`,
        opacity: `${1 - (i * 0.06 + 0.3)}`,
      }}
    />
  )


  return (
    <div className='Stamp' style={{
      width: size,
      height: size,
      ...style,
    }}>
      {matrix.map((c,i) => animated ? (
        <div className="animation-wrapper" style={{
          animationDuration: Math.random()*3 + 2 + 's',
          animationDelay: Math.random()*3 + 's',
        }}>
          {renderPoint(c,i)}
        </div>
      ) : renderPoint(c,i) )}
    </div>
  );
}

Stamp.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  accent: PropTypes.bool,
  style: PropTypes.object,
}

Stamp.defaultProps = {
  size: 120,
  accent: false,
  style: {},
}

export default Stamp
