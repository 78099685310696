import React from 'react'
import PropTypes from 'prop-types'
import Stamp from './Stamp'

function contentSize(s) {
  switch(s){
    case 'lg':
      return '1100px'
    case 'md':
      return '800px'
    case 'sm':
      return '500px'
    default:
      return '100%'
  }
}

const Section = ({children, size, style, id, title, containerClass, contentClass, stamp}) => {

  return (
    <section id={id} className={`Section ${containerClass}`}>
      <div style={{width: contentSize(size)}}>
        <div className={`Section-content ${contentClass}`} style={{...style}}>
          {title && (
            <>
              <h2 className="title is-2 highlight bold caps">{title}</h2>
              <figure className='accent-dash'></figure>
            </>
          )}
          {children}
        </div>
        {stamp && <Stamp style={{
          top: 0,
          left: 0,
          transform: 'translate(-65%, -55%)',
        }} />}
      </div>
    </section>
  )
}

Section.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  style: PropTypes.object,
  id: PropTypes.string,
  title: PropTypes.string,
  containerClass: PropTypes.string,
  contentClass: PropTypes.string,
  stamp: PropTypes.bool,
}

Section.defaultProps = {
  style: {},
  containerClass: '',
  contentClass: '',
  stamp: false,
}

export default Section
