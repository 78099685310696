import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import ResumeButton from './ResumeButton'

import Stamp from './Stamp'

const Header = ({ siteTitle }) => {

  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mlogo-theme.png" }) {
        publicURL
      }
    }
  `).file.publicURL

  return (
    <nav className="Header navbar" role="navigation" aria-label="main navigation">
      <a className="Header-brand title is-1" href="/">
        <span className='backstamp'>
          <Stamp height="100%" width="100%" />
        </span>
        <img src={logo} alt='Michael Li'/>
      </a>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
