import React from 'react'
import PropTypes from 'prop-types'
import SocialMenu from './SocialMenu'

import { socialLinks } from '../data/menus'

const SocialPanel = ({ className }) => {

  const panelHeight = (socialLinks.length * 3) + 2 + 'rem'
  const stickyOffset = `calc(100vh - ${panelHeight})`

  return (
    <div className={`SocialPanel ${className}`}>
      <SocialMenu style={{top: stickyOffset}} labelClass='Sidebar-item-text' />
    </div>
  )
}

SocialPanel.propTypes = {
  className: PropTypes.string,
}

SocialPanel.defaultProps = {
  className: '',
}

export default SocialPanel
