import React from 'react'
import PropTypes from 'prop-types'

import { navLinks } from '../data/menus'

const NavPanel = ({ className }) => {

  return (
    <nav className={`NavPanel ${className}`}>
      <ul>
        {navLinks.map((link,i) => (
          <a key={i} href={link.href}>
            <li>
              {link.icon}
              <div className='Sidebar-item-text'>
                <span>{link.name}</span>
              </div>
            </li>
          </a>
        ))}
      </ul>
    </nav>
  )
}

NavPanel.propTypes = {
  className: PropTypes.string,
}

NavPanel.defaultProps = {
  className: '',
}

export default NavPanel
