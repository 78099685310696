import React from 'react'
import PropTypes from 'prop-types'

const ResumeButton = ({text, styled}) => {
  return (
    <div className="ResumeButton">
      {styled && (
        <span style={{marginRight: '1rem'}}>&#8226; &#8226; &#8226; &#8226; &#8226;</span>
      )}
      <a href="#" className="button is-primary is-outlined">{text}</a>
    </div>
  )
}

ResumeButton.propTypes = {
  text: PropTypes.string,
  styled: PropTypes.bool,
}

ResumeButton.defaultProps = {
  text: 'Resumé',
  styled: false,
}

export default ResumeButton
