import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

export const navLinks = [
  {
    name: 'Top',
    href: '/#top',
    icon: <FA icon="arrow-up" />,
  },
  {
    name: 'About',
    href: '/#about',
    icon: <FA icon="user" />,
  },
  {
    name: 'Projects',
    href: '/#projects',
    icon: <FA icon="briefcase" />,
  },
  {
    name: 'On the web',
    href: '/#web',
    icon: <FA icon="globe-americas"/>,
  },
  {
    name: 'Contact',
    href: '/#contact',
    icon: <FA icon="envelope" />,
  },
]

export const socialLinks = [
  {
    name: 'Linkedin',
    href: 'https://www.linkedin.com/in/mcli830/',
    icon: <FA icon={['fab', 'linkedin']}/>,
  },
  {
    name: 'Github',
    href: 'https://github.com/mcli830',
    icon: <FA icon={['fab', 'github']}/>,
  },
  {
    name: 'AngelList',
    href: 'https://angel.co/mcli830',
    icon: <FA icon={['fab', 'angellist']}/>,
  },
  {
    name: 'Codepen',
    href: 'https://codepen.io/mykolodon',
    icon: <FA icon={['fab', 'codepen']}/>,
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/mcli_/',
    icon: <FA icon={['fab', 'instagram']}/>,
  },
]
