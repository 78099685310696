import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SocialMenu from './SocialMenu'
import { navLinks } from '../data/menus'
import { splitArray } from '../lib/array'

const Footer = (props) => {

  const data = useStaticQuery(graphql`
    query {
      gatsby: file(relativePath: {eq: "gatsby.png"}) {
        publicURL
      }
      bulma: file(relativePath: {eq: "bulma.png"}) {
        publicURL
      }
    }
  `)

  const sitemap = navLinks.slice(0,navLinks.length-1)

  const [sitemapA, sitemapB] = splitArray(sitemap)

  const LinkRow = ({links}) => (
    <div className='Footer-sitemap-row'>
      {links.map((link, i) => (
        <div key={i} className='Footer-sitemap-item'>
          <a href={link.href}>
            {link.name}
          </a>
        </div>
      ))}
    </div>
  )

  return (
    <footer className="Footer">
      <div className="Footer-sitemap">
        <LinkRow links={sitemapA} />
        <LinkRow links={sitemapB} />
      </div>

      <SocialMenu className='Footer-social' />
      
      <div>
        <p className="Footer-logos">
          <a href='https://www.gatsbyjs.org/' title="Made with Gatsby">
            <img src={data.gatsby.publicURL} alt='Gatsby'/>
          </a>
          <span>+</span>
          <a href='https://bulma.io/' title="Made with Bulma">
            <img src={data.bulma.publicURL} alt='Bulma'/>
          </a>
        </p>
      </div>
      <div>
        Built by Michael Li
        © {new Date().getFullYear()}
      </div>
    </footer>
  )
}

export default Footer
