import React from 'react'
import PropTypes from 'prop-types'

import { socialLinks } from '../data/menus'

const SocialMenu = ({ className, style, labelClass }) => {

  return (
    <ul className={className} style={{...style}}>
      {socialLinks.map((link,i) => (
        <a key={i} href={link.href} target="_blank" rel="noopener noreferrer">
          <li>
            {link.icon}
            {labelClass && (
              <label className={labelClass}>
                <span>{link.name}</span>
              </label>
            )}
          </li>
        </a>
      ))}
    </ul>
  )
}

SocialMenu.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  labelClass: PropTypes.string,
}

SocialMenu.defaultProps = {
  style: {},
}

export default SocialMenu
